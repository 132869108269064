import { jwtVerify } from 'jose';

export default defineNuxtRouteMiddleware(async (to, from) => {
  const token = useCookie("access_token");

  if (to.params.id === "1") {
    throw createError({
      statusCode: 404,
      statusMessage: "Pagina niet gevonden",
    });
  }

  if (!token.value && to.path !== "/login" && to.path !== "/createPassword" && to.path !== "/requestPasswordReset") {
    return navigateTo("/login");
  }

  // if (token.value) {
  //   try {
  //     const secret = new TextEncoder().encode('bRhYJRlZvBj2vW4MrV5HVdPgIE6VMtCFB0kTtJ1m');
  //     const { payload } = await jwtVerify(token.value, secret);
  //     // Optionally, you can add more checks on the decoded token
  //   } catch (err) {
  //     if (to.path !== "/login" && to.path !== "/createPassword" && to.path !== "/requestPasswordReset") {
  //       return navigateTo("/login");
  //     }
  //   }
  // }

  return;
});